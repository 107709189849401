<template>
  <v-dialog fullscreen persistent :value="true">
    <v-card tile>
      <v-card-title class="msaBlue white--text" :key="conditionalsLoaded">
        Mass Edit Users
      </v-card-title>

      <!-- desktop menu -->
      <v-tabs v-if="!isMobile" v-model="tab" background-color="lightGrey" grow>
        <v-tab> Select Users </v-tab>
        <v-tab :disabled="!usersSelected"> Details & Permissions </v-tab>
        <v-tab :disabled="!usersSelected"> Supervisors & Credentials </v-tab>
        <v-tab v-show="!isCustomUserFieldsDisabled" :disabled="!usersSelected">
          Custom User Fields
        </v-tab>
        <v-tab :disabled="!usersSelected"> Emergency Contacts </v-tab>
      </v-tabs>

      <!-- mobile menu -->
      <v-app-bar v-else color="lightGrey" dense>
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-app-bar-nav-icon v-bind="attrs" v-on="on"></v-app-bar-nav-icon>
          </template>
          <v-list>
            <v-list-item-group v-model="tab" mandatory>
              <v-list-item>
                <v-list-item-title>Select Users</v-list-item-title>
              </v-list-item>

              <v-list-item v-if="usersSelected">
                <v-list-item-title>Details & Permissions</v-list-item-title>
              </v-list-item>

              <v-list-item v-if="usersSelected">
                <v-list-item-title>Supervisors & Credentials</v-list-item-title>
              </v-list-item>

              <v-list-item v-if="usersSelected && !isCustomUserFieldsDisabled">
                <v-list-item-title>Custom User Fields</v-list-item-title>
              </v-list-item>

              <v-list-item v-if="usersSelected">
                <v-list-item-title>Emergency Contacts</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <UserMassEditSelectUsers ref="tab" v-if="tab == 0" />
        </v-tab-item>
        <v-tab-item>
          <UserMassEditDetailsAndPermissions
            ref="tab"
            v-if="tab == 1"
            @next="tab++"
            @done="done"
            @reset="reset"
          />
        </v-tab-item>
        <v-tab-item>
          <UserMassEditGroupsAndCredentials
            ref="tab"
            v-if="tab == 2"
            @next="tab++"
            @done="done"
            @reset="reset"
          />
        </v-tab-item>
        <v-tab-item>
          <UserMassEditCustomUserFields
            ref="tab"
            v-if="tab == 3"
            @done="done"
            @next="tab++"
          />
        </v-tab-item>
        <v-tab-item>
          <UserMassEditEmergencyContacts
            ref="tab"
            v-if="tab == 4"
            @done="done"
            @reset="reset"
          />
        </v-tab-item>
      </v-tabs-items>

      <v-card-actions>
        <v-btn @click="close" text color="red" class="px-0"> Cancel </v-btn>
        <v-spacer></v-spacer>

        <v-badge
          v-if="tab == 0 && usersSelected"
          :content="usersSelectedLength"
          :value="usersSelectedLength"
          overlap
          color="green"
          :offset-x="badgeOffsetX"
        >
          <v-btn @click="tab++" class="msaBlue white--text"> Next </v-btn>
        </v-badge>

        <v-btn v-if="tab > 0" @click="tab--" text color="msaBlue" class="mr-4">
          Back
        </v-btn>
        <v-btn
          v-if="tab > 0 && tab < 4"
          @click="$refs.tab.next()"
          class="green white--text mr-4"
        >
          Save & Next
        </v-btn>
        <v-btn
          v-if="tab > 0"
          @click="$refs.tab.done()"
          class="msaBlue white--text"
        >
          Save & Done
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UserMassEditSelectUsers from '@/components/User/UserMassEditSelectUsers.vue';
import UserMassEditDetailsAndPermissions from '@/components/User/UserMassEditDetailsAndPermissions.vue';
import UserMassEditGroupsAndCredentials from '@/components/User/UserMassEditGroupsAndCredentials.vue';
import UserMassEditEmergencyContacts from '@/components/User/UserMassEditEmergencyContacts.vue';
import UserMassEditCustomUserFields from '@/components/User/UserMassEditCustomUserFields.vue';

export default {
  name: 'UserMassEditPage',
  components: {
    UserMassEditSelectUsers,
    UserMassEditDetailsAndPermissions,
    UserMassEditGroupsAndCredentials,
    UserMassEditEmergencyContacts,
    UserMassEditCustomUserFields,
  },
  data() {
    return {
      tab: 0,
      isCustomUserFieldsDisabled: true,
      conditionalsLoaded: false,
    };
  },
  mounted() {
    const url = 'get-input-fields-for-organization';
    const params = {
      loaderText: 'Loading',
      orgId: this.$store.getters.isInternalAdmin
        ? this.$store.getters.selectedCompany.id
        : null,
    };

    this.$axios.post(url, params).then((response) => {
      if (response.data.length) {
        this.isCustomUserFieldsDisabled = false;
        this.conditionalsLoaded = true;
      }
    });
  },
  methods: {
    done() {
      this.$store.commit('updateUsersSelected', []);
      if (this.$store.getters.isInternalAdmin) {
        this.$router.push({
          name: 'IACompanyUsers',
        });
      } else {
        this.$router.push({
          name: 'CompanyUsers',
        });
      }
    },
    close() {
      if (this.usersSelectedLength > 0) {
        let message = 'All users selected will be deselected';
        if (this.tab > 0) {
          message += ' and any unsaved changes will be lost';
        }
        message += '. Are you sure you want to cancel?';
        this.$root.showMessage(
          'Are you sure?',
          message,
          () => this.done(),
          () => null,
          'Yes, cancel',
          'NO',
        );
      } else {
        this.done();
      }
    },
    reset() {
      this.$nextTick(() => {
        this.$store.commit('updateUsersSelected', []);
        this.tab = 0;
      });
    },
  },
  computed: {
    usersSelected() {
      return this.usersSelectedLength > 0;
    },
    usersSelectedLength() {
      return this.$store.getters.usersSelected.length;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    badgeOffsetX() {
      switch (this.usersSelectedLength.toString().length) {
        case 1:
          return 0;
        case 2:
          return 10;
        case 3:
          return 20;
        default:
          return 0;
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'UserMassEditPage') {
      this.$store.commit('updateUsersSelected', []);
    }
    next();
  },
};
</script>
