<template>
  <v-container fluid>
    <!-- GLOBAL EDIT DIALOG -->
    <v-row>
      <v-col align="center">
        <v-dialog v-model="dialog" max-width="500px" persistent>
          <template v-slot:activator="{ on: dialog, attrs }">
            <v-btn
              v-bind="attrs"
              v-blur
              v-on="{ ...dialog }"
              class="msaBlue white--text"
            >
              <v-icon>mdi-pencil</v-icon>
              GLOBAL EDIT
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="msaBlue white--text">
              <v-row>
                <v-col class="grow"> Global Edit </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="mt-4">
              <v-row
                v-for="globalField in globalCustomFields"
                :key="globalField.id"
                dense
              >
                <v-col cols="12">
                  <v-text-field
                    v-model.trim="globalField.value"
                    dense
                    :label="globalField.name"
                    maxLength="500"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="
                  clearGlobalValues();
                  dialog = false;
                "
                class="mr-5"
                color="red"
                text
              >
                CANCEL
              </v-btn>
              <v-btn @click="applyGlobalValues" color="msaBlue white--text">
                APPLY TO ALL
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <!-- USERS AND FIELDS -->
    <v-simple-table :height="tableHeight">
      <template v-slot:default>
        <tbody>
          <tr v-for="user in users" :key="user.id">
            <td :style="styles.general">
              <v-row no-gutters>
                <v-col cols="12">
                  {{ user.fullName | truncate }}
                </v-col>
                <v-col class="grey--text">
                  {{ user.title | truncate }}
                </v-col>
              </v-row>
            </td>
            <td :style="styles.general" class="pt-6">
              <v-form>
                <v-row
                  v-for="inputField in user.inputFields"
                  :key="inputField.fieldId"
                  no-gutters
                >
                  <v-col>
                    <v-text-field
                      v-model.trim="inputField.value"
                      :label="inputField.name"
                      dense
                      maxlength="500"
                      outlined
                    />
                  </v-col>
                </v-row>
              </v-form>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
export default {
  name: 'UserMassEditCustomUserFields',
  filters: {
    truncate(text, length = 25) {
      if (text.length <= length) {
        return text;
      }
      return text.substring(0, length) + '...';
    },
  },
  data() {
    return {
      styles: {
        general: {
          minWidth: '180px',
        },
        action: {
          width: '48px',
          maxWidth: '48px',
          minWidth: '48px',
        },
      },
      dialog: false,
      isUsersLoaded: false,
      unchangedUsers: [],
      users: [],
      globalCustomFields: [],
    };
  },
  computed: {
    screenHeight() {
      return window.innerHeight;
    },
    tableHeight() {
      const tenItems = 350;
      const top = 170;
      const bottom = 50;
      if (this.screenHeight - top - bottom > tenItems) {
        return this.screenHeight - top - bottom;
      } else {
        return tenItems;
      }
    },
  },
  mounted() {
    this.getCustomInputFields();
  },
  methods: {
    applyGlobalValues() {
      let index = 0;
      for (const globalField of this.globalCustomFields) {
        if ('value' in globalField && globalField.value.trim().length) {
          for (const user of this.users) {
            user.inputFields[index].value = globalField.value;
          }
        }
        index++;
      }
      this.clearGlobalValues();

      this.dialog = false;
    },
    clearGlobalValues() {
      for (const field of this.globalCustomFields) {
        delete field.value;
      }
    },
    getCustomInputFields() {
      const url = 'get-input-fields-for-many-users?format=json';
      const params = {
        loaderText: 'Loading...',
        userIds: this.$store.getters.usersSelected,
        companyId: this.$store.getters.isInternalAdmin
          ? this.$store.getters.selectedCompany.id
          : null,
      };

      this.$axios.post(url, params).then((response) => {
        this.users = response.data.usersFields;
        this.unchangedUsers = JSON.parse(JSON.stringify(this.users));

        this.globalCustomFields = response.data.orgFields;
      });
    },
    saveCustomInputFields() {
      const url = 'update-input-fields-for-many-users?format=json';
      const params = {
        loaderText: 'Loading...',
        users: this.users,
        orgId: this.$store.getters.isInternalAdmin
          ? this.$store.getters.selectedCompany.id
          : null,
      };

      if (!this.isChanges()) {
        return new Promise((resolve) => resolve());
      }

      return this.$axios.post(url, params);
    },
    next() {
      this.saveCustomInputFields()
        .then(() => {
          this.$emit('next');
        })
        .catch((errors) => {
          this.handleErrors(errors.response.data.errors);
        });
    },
    done() {
      this.saveCustomInputFields()
        .then(() => {
          this.$emit('done');
        })
        .catch((errors) => {
          this.handleErrors(errors.response.data.errors);
        });
    },
    isChanges() {
      return JSON.stringify(this.unchangedUsers) != JSON.stringify(this.users);
    },
  },
};
</script>
