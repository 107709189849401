<template>
  <v-container fluid>
    <v-row>
      <v-col align="right" v-if="isInternalAdmin">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="msaBlue" dark @click="initGlobalGroups" v-on="on">
              add/remove groups
            </v-btn>
          </template>
          Apply to all users
        </v-tooltip>
      </v-col>

      <v-col :class="{ shrink: isInternalAdmin }" align="right">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="msaBlue"
              dark
              @click="initGlobalSupervisors"
              v-on="on"
            >
              add/remove supervisors
            </v-btn>
          </template>
          Apply to all users
        </v-tooltip>
      </v-col>

      <v-col align="left">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="msaBlue" dark @click="initGlobalPassword" v-on="on">
              password and send
            </v-btn>
          </template>
          Apply to all users
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col :style="{ height: tableHeight + 'px', overflow: 'scroll' }">
        <v-row
          v-for="user in groupsAndCredentials"
          :key="user.id"
          class="flex-nowrap"
        >
          <v-col :style="styles.name" class="pt-5">
            <v-row no-gutters>
              <v-col cols="12">
                {{ user.fullname | truncate }}
              </v-col>
              <v-col class="grey--text">
                {{ user.title | truncate }}
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="isInternalAdmin" :style="styles.general">
            <v-autocomplete
              v-model="user.groups"
              :items="groups"
              dense
              multiple
              outlined
              label="Groups"
              item-text="name"
              item-value="id"
              chips
              deletable-chips
              clearable
            >
              <template v-slot:selection="{ item, index }">
                <v-chip close small @click:close="user.groups.splice(index, 1)">
                  {{ item.name | truncate }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col :style="styles.general">
            <v-autocomplete
              v-model="user.supervisors"
              :items="availableSupervisors(user.id)"
              dense
              multiple
              outlined
              label="Supervisors"
              item-text="fullname"
              item-value="id"
              chips
              deletable-chips
              clearable
            >
              <template v-slot:selection="{ item, index }">
                <v-chip
                  close
                  small
                  @click:close="user.supervisors.splice(index, 1)"
                >
                  {{ item.fullname | truncate }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col :style="styles.general">
            <v-text-field
              v-model="user.username"
              :rules="rules.username"
              outlined
              dense
              :error-messages="user.errors ? user.errors.username : []"
              @keydown="clearServerError(user, 'username')"
            >
              <template v-slot:label>
                Username<span class="red--text">*</span>
              </template>
            </v-text-field>
          </v-col>
          <v-col :style="styles.general">
            <v-text-field
              v-model.trim="user.password"
              @keyup="updatePassword($event, user)"
              @keydown.space.prevent
              :rules="user.password ? rules.password : []"
              :label="userPasswordLabel(user.password)"
              placeholder="******"
              dense
              outlined
            >
              <template v-slot:append-outer>
                <a @click="generatePassword(user)">
                  <v-icon color="msaBlue">mdi-cached</v-icon>
                </a>
              </template>
            </v-text-field>
          </v-col>
          <v-col :style="styles.sendCredentials">
            <v-checkbox
              v-model="user.sendCredentialEmail"
              :disabled="!user.email.length || !user.password.length"
              :true-value="1"
              :false-value="0"
              class="ma-0"
              label="Send Credentials"
            >
            </v-checkbox>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- global groups -->
    <v-dialog v-model="globalGroupsDialog" width="500px" persistent>
      <v-card>
        <v-card-title class="msaBlue white--text">
          Apply groups to all users
        </v-card-title>
        <v-card-text class="pt-4">
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="globalValues.groups.add"
                dense
                chips
                multiple
                outlined
                clearable
                :items="availableGlobalGroupsForAdd"
                item-text="name"
                item-value="id"
                label="Select groups to add to all users"
                deletable-chips
                hide-details
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="globalValues.groups.remove"
                dense
                chips
                multiple
                outlined
                clearable
                :items="availableGlobalGroupsForRemove"
                item-text="name"
                item-value="id"
                label="Select groups to remove from all users"
                deletable-chips
                hide-details
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text dark color="red" @click="globalGroupsDialog = false">
            Cancel
          </v-btn>
          <v-btn class="msaBlue white--text" @click="applyGlobalGroups">
            apply to all
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- global supervisors -->
    <v-dialog v-model="globalSupervisorsDialog" width="500px" persistent>
      <v-card>
        <v-card-title class="msaBlue white--text">
          Apply supervisors to all users
        </v-card-title>
        <v-card-text class="pt-4">
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="globalValues.supervisors.add"
                :items="availableGlobalSupervisorsForAdd"
                dense
                chips
                multiple
                outlined
                clearable
                item-value="id"
                deletable-chips
                label="Select supervisors to add to all users"
                item-text="fullname"
                hide-details
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="globalValues.supervisors.remove"
                :items="availableGlobalSupervisorsForRemove"
                dense
                chips
                multiple
                outlined
                clearable
                item-value="id"
                deletable-chips
                label="Select supervisors to remove from all users"
                item-text="fullname"
                hide-details
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text dark color="red" @click="globalSupervisorsDialog = false">
            Cancel
          </v-btn>
          <v-btn class="msaBlue white--text" @click="applyGlobalSupervisors">
            apply to all
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- global password -->
    <v-dialog v-model="globalPasswordDialog" width="500px" persistent>
      <v-card>
        <v-card-title class="msaBlue white--text">
          Apply password to all users
        </v-card-title>
        <v-card-text class="pt-4">
          <v-row>
            <v-col>
              <v-text-field
                v-model="globalValues.password"
                @change="updateAllPasswords"
                :rules="rules.password"
                label="Password"
                outlined
                dense
              >
                <template v-slot:append-outer>
                  <a @click="generateAllPasswords()">
                    <v-icon color="msaBlue">mdi-cached</v-icon>
                  </a>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-checkbox
                v-model="globalValues.sendCredentialEmail"
                :true-value="1"
                :false-value="0"
                class="ma-0"
                label="Send Credentials"
                :disabled="!globalValues.password.length"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text dark color="red" @click="globalPasswordDialog = false">
            Cancel
          </v-btn>
          <v-btn class="msaBlue white--text" @click="applyGlobalPassword">
            apply to all
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { PasswordGenerator } from '@/plugins/PasswordGenerator.js';

export default {
  name: 'UserMassEditGroupsAndCredentials',
  data() {
    return {
      dialog: false,
      globalValues: {
        groups: {
          add: [],
          remove: [],
        },
        supervisors: {
          add: [],
          remove: [],
        },
        password: '',
        sendCredentialEmail: 0,
        isPasswordRandom: 0,
      },
      styles: {
        general: {
          minWidth: '300px',
        },
        name: {
          maxWidth: '240px',
          minWidth: '160px',
        },
        sendCredentials: {
          maxWidth: '200px',
          minWidth: '160px',
          textAlign: '-webkit-center',
        },
      },
      rules: {
        username: [(v) => this.$helpers.validateUsername(v)],
        password: [(v) => this.$helpers.validatePassword(v, false)],
      },
      groupsAndCredentials: [],
      originalGroupsAndCredentials: [],
      groups: [],
      supervisors: [],
      globalGroupsDialog: false,
      globalSupervisorsDialog: false,
      globalPasswordDialog: false,
    };
  },
  computed: {
    isInternalAdmin() {
      return this.$store.getters.isInternalAdmin;
    },
    availableSupervisors() {
      return (userId) => {
        const supervisors = this.supervisors.filter(
          (supervisor) => supervisor.id != userId,
        );
        return supervisors;
      };
    },
    screenHeight() {
      return window.innerHeight;
    },
    tableHeight() {
      const tenItems = 350;
      const top = 160;
      const bottom = 60;
      if (this.screenHeight - top - bottom > tenItems) {
        return this.screenHeight - top - bottom;
      } else {
        return tenItems;
      }
    },
    userPasswordLabel() {
      return (password) => (password.length ? 'Password' : '');
    },
    availableGlobalGroupsForAdd() {
      return this.groups.filter(
        (group) => !this.globalValues.groups.remove.includes(group.id),
      );
    },
    availableGlobalGroupsForRemove() {
      return this.groups.filter(
        (group) => !this.globalValues.groups.add.includes(group.id),
      );
    },
    availableGlobalSupervisorsForAdd() {
      return this.supervisors.filter(
        (supervisor) =>
          !this.globalValues.supervisors.remove.includes(supervisor.id),
      );
    },
    availableGlobalSupervisorsForRemove() {
      return this.supervisors.filter(
        (supervisor) =>
          !this.globalValues.supervisors.add.includes(supervisor.id),
      );
    },
  },
  methods: {
    clearServerError(user, field) {
      if (user.errors && user.errors[field]) {
        user.errors[field] = [];
      }
    },
    getDifferences() {
      const differences = [];
      for (let index = 0; index < this.groupsAndCredentials.length; index++) {
        const original = this.originalGroupsAndCredentials[index];
        const edited = this.groupsAndCredentials[index];
        if (JSON.stringify(original) !== JSON.stringify(edited)) {
          differences.push(edited);
        }
      }
      return differences;
    },
    next() {
      this.saveChanges()
        .then(() => {
          this.$emit('next');
        })
        .catch((errors) => {
          this.handleErrors(errors.response.data.errors);
        });
    },
    done() {
      this.saveChanges()
        .then(() => {
          this.$emit('done');
        })
        .catch((errors) => {
          this.handleErrors(errors.response.data.errors);
        });
    },
    handleErrors(errors) {
      if (errors.invalidUsers.length > 0) {
        errors.invalidUsers.forEach((user) => {
          let index = this.groupsAndCredentials.findIndex(
            (u) => u.id == user.id,
          );
          if (index > -1) {
            this.groupsAndCredentials.splice(index, 1);
            this.originalGroupsAndCredentials.splice(index, 1);
          }
          index = this.$store.getters.usersSelected.findIndex(
            (u) => u == user.id,
          );
          if (index > -1) {
            this.$store.commit(
              'updateUsersSelected',
              this.$store.getters.usersSelected.splice(index, 1),
            );
          }
        });
        if (this.groupsAndCredentials.length == 0) {
          this.$emit('reset');
        }
      }
      const invalidSupervisorIds = Object.keys(errors.invalidSupervisors);
      if (invalidSupervisorIds.length > 0) {
        this.supervisors = this.supervisors.filter(
          (supervisor) =>
            !invalidSupervisorIds.includes(supervisor.id.toString()),
        );
        this.groupsAndCredentials.forEach((user) => {
          user.supervisors = user.supervisors.filter(
            (supervisor) =>
              !invalidSupervisorIds.includes(supervisor.toString()),
          );
        });
      }
      if (errors.hasIndividualErrors) {
        errors.users.forEach((user) => {
          const index = this.groupsAndCredentials.findIndex(
            (u) => u.id == user.id,
          );
          if (index > -1) {
            this.$set(this.groupsAndCredentials, index, user);
          }
        });
      }
    },
    saveChanges() {
      const differences = this.getDifferences();

      if (differences.length == 0) {
        return new Promise((resolve) => resolve());
      }

      const url = 'mass-edit-users-groups-and-credentials?format=json';
      const params = {
        loaderText: 'Saving...',
        users: differences,
      };
      if (this.$store.getters.isInternalAdmin) {
        params.companyId = this.$store.getters.selectedCompany.id;
      }
      return this.$axios.post(url, params);
    },
    updateAllPasswords() {
      this.globalValues.isPasswordRandom = 0;
    },
    updatePassword(event, user) {
      /**
       * Without key check, when user would copy credentials from the text field,
       * it would detect that command a.k.a. META (or other non-'normal' keys were detected)
       * and the program would think a user is updating their passsword due to key press.
       * META + C or other key combinations are not detected as individual key inputs (META then C)
       * And it will only be detected as META
       */

      // Prevents errors from flooding console when Loading page
      if (!event.key || !event.key.length) {
        return;
      }

      if (event.key.length == 1 && event.code != 'Space') {
        user.sendCredentialEmail = 0;
        user.isPasswordRandom = 0;
      }
    },
    generateAllPasswords() {
      const numLetters = 6;
      const generatedPassword = PasswordGenerator.generate(
        numLetters,
        this.$constants.PASSWORD_VALIDATION_REGEX,
      );
      this.globalValues.password = generatedPassword;
      this.globalValues.isPasswordRandom = 1;
    },
    generatePassword(user) {
      const numLetters = 6;
      user.password = PasswordGenerator.generate(
        numLetters,
        this.$constants.PASSWORD_VALIDATION_REGEX,
      );
      user.isPasswordRandom = 1;
    },
    getUsersGroupsAndCredentials() {
      this.originalGroupsAndCredentials = [];
      this.groupsAndCredentials = [];
      const url = 'get-users-groups-and-credentials?format=json';
      const params = {
        loaderText: 'Loading...',
        userIds: this.$store.getters.usersSelected,
      };
      if (this.isInternalAdmin) {
        params.companyId = this.$store.getters.selectedCompany.id;
      }

      this.$axios.post(url, params).then((response) => {
        this.originalGroupsAndCredentials = response.data.users;
        this.groupsAndCredentials = JSON.parse(
          JSON.stringify(this.originalGroupsAndCredentials),
        );
        this.supervisors = response.data.supervisors;
        this.groups = response.data.groups;
      });
    },
    initGlobalGroups() {
      this.globalValues.groups.add = [];
      this.globalValues.groups.remove = [];
      this.globalGroupsDialog = true;
    },
    initGlobalSupervisors() {
      this.globalValues.supervisors.add = [];
      this.globalValues.supervisors.remove = [];
      this.globalSupervisorsDialog = true;
    },
    initGlobalPassword() {
      this.globalValues.password = '';
      this.globalValues.sendCredentialEmail = 0;
      this.globalValues.isPasswordRandom = 0;
      this.globalPasswordDialog = true;
    },
    applyGlobalGroups() {
      this.groupsAndCredentials.forEach((user) => {
        user.groups = [
          ...new Set(
            user.groups
              .concat(this.globalValues.groups.add)
              .filter(
                (group) => !this.globalValues.groups.remove.includes(group),
              ),
          ),
        ];
      });
      this.globalGroupsDialog = false;
    },
    applyGlobalSupervisors() {
      this.groupsAndCredentials.forEach((user) => {
        user.supervisors = [
          ...new Set(
            user.supervisors
              .concat(this.globalValues.supervisors.add)
              .filter(
                (supervisor) =>
                  !this.globalValues.supervisors.remove.includes(supervisor),
              ),
          ),
        ];
      });
      this.globalSupervisorsDialog = false;
    },
    applyGlobalPassword() {
      if (this.globalValues.password.length == 0) {
        this.globalValues.isPasswordRandom = 0;
        this.globalValues.sendCredentialEmail = 0;
        this.globalPasswordDialog = false;
        return;
      }
      this.groupsAndCredentials.forEach((user) => {
        user.password = this.globalValues.password;
        user.isPasswordRandom = this.globalValues.isPasswordRandom;
        user.sendCredentialEmail = this.globalValues.sendCredentialEmail;
      });
      this.globalPasswordDialog = false;
    },
  },
  filters: {
    truncate(text, length = 25) {
      if (text.length <= length) {
        return text;
      }
      return text.substring(0, length) + '...';
    },
  },
  mounted() {
    this.getUsersGroupsAndCredentials();
  },
};
</script>
