<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-row dense>
          <v-col cols="4"> Supervisor </v-col>
          <v-col>
            <CustomizedAutoComplete
              :items="supervisors"
              :value="filters.supervisors"
              @change="filters.supervisors = $event"
              clearable
              deletable-chips
              hide-details
              item-text="fullname"
              label="Select supervisor(s)"
              multiple
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="4"> Group </v-col>
          <v-col>
            <CustomizedAutoComplete
              :items="labelsForFilter"
              :value="filters.labels"
              @change="filters.labels = $event"
              clearable
              deletable-chips
              hide-details
              label="Select group(s)"
              multiple
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col> Hire Date </v-col>
          <v-col class="mr-2">
            <v-menu
              v-model="hireDateFromMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              dense
              min-width="auto"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filters.startDate"
                  v-bind="attrs"
                  v-on="on"
                  append-icon="mdi-calendar"
                  clearable
                  dense
                  hide-details
                  outlined
                  placeholder="From"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.startDate"
                dense
                no-title
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-menu
              v-model="hireDateToMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              dense
              min-width="auto"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filters.endDate"
                  v-bind="attrs"
                  v-on="on"
                  append-icon="mdi-calendar"
                  clearable
                  dense
                  hide-details
                  outlined
                  placeholder="To"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.endDate"
                dense
                no-title
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col> User Added </v-col>
          <v-col class="mr-2">
            <v-menu
              v-model="userAddedFromMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              dense
              min-width="auto"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filters.createdFrom"
                  v-bind="attrs"
                  v-on="on"
                  append-icon="mdi-calendar"
                  clearable
                  dense
                  hide-details
                  outlined
                  placeholder="From"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.createdFrom"
                dense
                no-title
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-menu
              v-model="userAddedToMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              dense
              min-width="auto"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filters.createdTo"
                  v-bind="attrs"
                  v-on="on"
                  append-icon="mdi-calendar"
                  clearable
                  dense
                  hide-details
                  outlined
                  placeholder="To"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.createdTo"
                dense
                no-title
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="4"> Web Admin </v-col>
          <v-col>
            <v-btn-toggle
              v-model="filters.isWebAdministrator"
              color="msaBlue"
              dense
              mandatory
              style="width: 100%"
            >
              <v-btn :value="2" class="text-capitalize" style="width: 33%">
                All
              </v-btn>
              <v-btn :value="1" class="text-capitalize" style="width: 33%">
                Yes
              </v-btn>
              <v-btn :value="0" class="text-capitalize" style="width: 33%">
                No
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="4"> Supervisor </v-col>
          <v-col>
            <v-btn-toggle
              v-model="filters.isSupervisor"
              color="msaBlue"
              dense
              mandatory
              style="width: 100%"
            >
              <v-btn :value="2" class="text-capitalize" style="width: 33%">
                All
              </v-btn>
              <v-btn :value="1" class="text-capitalize" style="width: 33%">
                Yes
              </v-btn>
              <v-btn :value="0" class="text-capitalize" style="width: 33%">
                No
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <v-row v-if="filters.isSupervisor" dense>
          <v-col cols="4"> Manager </v-col>
          <v-col>
            <v-btn-toggle
              v-model="filters.isManager"
              color="msaBlue"
              dense
              mandatory
              style="width: 100%"
            >
              <v-btn :value="2" class="text-capitalize" style="width: 33%">
                All
              </v-btn>
              <v-btn :value="1" class="text-capitalize" style="width: 33%">
                Yes
              </v-btn>
              <v-btn :value="0" class="text-capitalize" style="width: 33%">
                No
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <v-row class="mb-3" dense>
          <v-col cols="4"> Safety </v-col>
          <v-col>
            <v-btn-toggle
              v-model="filters.isSafetyRep"
              color="msaBlue"
              dense
              mandatory
              style="width: 100%"
            >
              <v-btn :value="2" class="text-capitalize" style="width: 33%">
                All
              </v-btn>
              <v-btn :value="1" class="text-capitalize" style="width: 33%">
                Yes
              </v-btn>
              <v-btn :value="0" class="text-capitalize" style="width: 33%">
                No
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col align="center">
            <v-btn @click="clearFilters" color="red" text> Clear Filter </v-btn>
            <v-btn @click="applyFilters" class="ml-4 msaBlue white--text">
              Apply Filter
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col>
        <v-row dense>
          <v-col>
            <v-text-field
              v-model.trim="search"
              append-icon="mdi-magnify"
              dense
              hide-details
              label="Search for user(s)"
              outlined
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-card>
              <v-data-table
                v-model="selected"
                :footer-props="{
                  'disable-items-per-page': true,
                  'items-per-page-options': [100],
                }"
                :headers="headers"
                :height="contentHeight"
                :items="availableUsers"
                :options.sync="options"
                :search="search"
                @item-selected="selectUser"
                @toggle-select-all="selectAllUsers"
                checkbox-color="msaBlue"
                disable-sort
                mobile-breakpoint="0"
                show-select
              >
                <template v-slot:[`item.fullName`]="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.fullName }}
                      <span class="grey--text"> ({{ item.username }}) </span>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'UserMassEditSelectUsers',
  data() {
    return {
      availableUsers: [],
      filters: JSON.parse(
        JSON.stringify(this.$constants.EMPLOYEE_LIST_FILTERS),
      ),
      headers: [
        {
          text: 'Select all',
          value: 'fullName',
          sortable: false,
        },
      ],
      hireDateFromMenu: false,
      hireDateToMenu: false,
      itemsCount: 0,
      labelsForFilter: [],
      options: {
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: 100,
        sortBy: 'name',
        sortDesc: ['false'],
      },
      search: '',
      selectAll: 0,
      selected: [],
      selectedUsers: [],
      userAddedFromMenu: false,
      userAddedToMenu: false,
      usersForFilter: [],
    };
  },
  computed: {
    usersSelected() {
      return this.$store.getters.usersSelected;
    },
    screenHeight() {
      return window.innerHeight;
    },
    contentHeight() {
      const minHeight = 285; // Table
      const top = 150;
      const bottom = 120; // Buttons
      if (this.screenHeight - top - bottom > minHeight) {
        return this.screenHeight - top - bottom;
      } else {
        return minHeight;
      }
    },
    supervisors() {
      if (this.usersForFilter.length) {
        return this.usersForFilter.filter((user) => user.isSupervisor);
      }
      return [];
    },
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    selectUser(event) {
      if (event.value && this.selected.length == 100) {
        this.$root.showMessage(
          'Warning',
          'Selection will exceed the maximum of 100 users. Action has been undone.',
          () => false,
          null,
          'OK',
          null,
        );

        this.selected = this.selected.slice(); // this.selected has yet to be updated so we do a slice to ensure the array does not get updated after return by v-data-table

        return;
      }

      // Wait for selected to have the user added/remove by v-data-table
      this.$nextTick(() => {
        this.$store.commit(
          'updateUsersSelected',
          this.selected.map((user) => user.id),
        );
      });
    },
    selectAllUsers(event) {
      if (!event.value) {
        const idsToRemove = event.items.map((user) => {
          return user.id;
        });
        const newSelected = this.usersSelected.filter(
          (id) => !idsToRemove.includes(id),
        );
        this.$store.commit('updateUsersSelected', newSelected);
        this.checkSelected();

        return;
      }

      const newIds = this.usersSelected.concat(
        event.items.map((user) => {
          return user.id;
        }),
      );
      const set = new Set(newIds);

      if (set.size > 100) {
        this.$root.showMessage(
          'Warning',
          `Selection will exceed the maximum of 100 users. There were ${this.selected.length} users selected prior to action.`,
          () => {},
          null,
          'OK',
          null,
        );

        this.selected = this.selected.slice(); // this.selected has yet to be updated so we do a slice to ensure the array does not get updated after return by v-data-table

        return;
      }

      this.$store.commit('updateUsersSelected', [...set]);
      this.checkSelected();
    },
    clearFilters() {
      this.filters = JSON.parse(
        JSON.stringify(this.$constants.EMPLOYEE_LIST_FILTERS),
      );
      this.options.page = this.$constants.PAGINATION.DEFAULT_PAGE;
      this.applyFilters();
    },
    applyFilters() {
      this.getUsers();
    },
    getUsers() {
      const params = {
        options: { ...this.options, itemsPerPage: -1 },
        filters: this.filters,
        loaderText: 'Loading...',
      };
      if (this.$store.getters.isInternalAdmin) {
        params.companyId = this.$store.getters.selectedCompany.id;
      }
      return this.$axios
        .post('get-users-by-company?format=json', params)
        .then((response) => {
          this.itemsCount = response.data.resultCount;

          // Don't allow users to mass manage 1Life accounts
          this.availableUsers = response.data.items.filter(
            (user) =>
              user.firstName.toLowerCase() !=
              this.$constants.ONE_LIFE_ACCOUNT.FIRST_NAME,
          );
        })
        .then(() => {
          this.checkSelected();
        })
        .then(() => this.getDataForFilter());
    },
    getDataForFilter() {
      const url = 'get-data-for-user-filter?format=json';
      const params = {
        loaderText: 'Loading...',
      };
      if (this.$store.getters.isInternalAdmin) {
        params.companyId = this.$store.getters.selectedCompany.id;
      }
      this.$axios.post(url, params).then((response) => {
        this.usersForFilter = response.data.users;
        this.labelsForFilter = response.data.labels;
      });
    },
    checkSelected() {
      this.selected = this.availableUsers.filter((user) =>
        this.usersSelected.includes(user.id),
      );
    },
  },
};
</script>
