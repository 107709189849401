<template>
  <v-simple-table :height="tableHeight">
    <template v-slot:default>
      <tbody>
        <tr v-for="(item, index) in emergencyContacts" :key="item.id">
          <td :style="styles.general">
            <v-row no-gutters>
              <v-col cols="12">
                {{ item.fullname | truncate }}
              </v-col>
              <v-col class="grey--text">
                {{ item.title | truncate }}
              </v-col>
            </v-row>
          </td>
          <td class="pt-6" :style="styles.general">
            <v-form ref="emergencyContacts">
              <v-row
                v-for="(ec, i) in item.emergencyContacts"
                :key="i"
                no-gutters
              >
                <v-col>
                  <v-text-field
                    v-model="ec.name"
                    outlined
                    :rules="rules.name"
                    dense
                    :error-messages="ec.errors ? ec.errors.name : []"
                    @keydown="clearServerError(ec, 'name')"
                    maxlength="150"
                  >
                    <template v-slot:label>
                      Name<span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col class="mx-4">
                  <v-text-field
                    v-model="ec.relationToUser"
                    outlined
                    :rules="rules.relationToUser"
                    dense
                    :error-messages="ec.errors ? ec.errors.relationToUser : []"
                    @keydown="clearServerError(ec, 'relationToUser')"
                    maxlength="64"
                  >
                    <template v-slot:label>
                      Relation<span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="ec.phone"
                    outlined
                    :rules="rules.phone"
                    dense
                    label="Phone"
                    :error-messages="ec.errors ? ec.errors.phone : []"
                    @keydown="clearServerError(ec, 'phone')"
                  >
                    <template v-slot:label>
                      Phone<span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col class="shrink ml-4">
                  <v-btn
                    color="red"
                    icon
                    @click="deleteEmergencyContact(item, i)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
            <v-row v-if="canAdd(item)" no-gutters>
              <v-col class="mb-4">
                <v-btn
                  outlined
                  depressed
                  color="msaBlue"
                  @click="addEmergencyContact(item, index)"
                >
                  <v-icon class="mr-4">mdi-account-plus</v-icon>
                  Add Emergency Contact
                </v-btn>
              </v-col>
            </v-row>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: 'UserMassEditEmergencyContacts',
  data() {
    return {
      styles: {
        general: {
          minWidth: '180px',
        },
        action: {
          width: '48px',
          maxWidth: '48px',
          minWidth: '48px',
        },
      },
      rules: {
        phone: [(v) => this.$helpers.validatePhone(v, true)],
        name: [(v) => this.$helpers.required(v, 'Name')],
        relationToUser: [(v) => this.$helpers.required(v, 'Relation')],
      },
      emergencyContacts: [],
      originalEmergencyContacts: [],
    };
  },
  computed: {
    screenHeight() {
      return window.innerHeight;
    },
    tableHeight() {
      const tenItems = 350;
      const top = 110;
      const bottom = 50;
      if (this.screenHeight - top - bottom > tenItems) {
        return this.screenHeight - top - bottom;
      } else {
        return tenItems;
      }
    },
    canAdd() {
      return (user) => user.emergencyContacts.length < 3;
    },
  },
  methods: {
    clearServerError(emergencyContact, field) {
      if (emergencyContact.errors) {
        emergencyContact.errors[field] = [];
      }
    },
    getDifferences() {
      const differences = [];
      for (let index = 0; index < this.emergencyContacts.length; index++) {
        const original = this.originalEmergencyContacts[index];
        const edited = this.emergencyContacts[index];
        if (JSON.stringify(original) !== JSON.stringify(edited)) {
          differences.push(edited);
        }
      }
      return differences;
    },
    next() {
      this.saveChanges()
        .then(() => {
          this.$emit('next');
        })
        .catch((errors) => {
          this.handleErrors(errors.response.data.errors);
        });
    },
    done() {
      this.saveChanges()
        .then(() => {
          this.$emit('done');
        })
        .catch((errors) => {
          this.handleErrors(errors.response.data.errors);
        });
    },
    handleErrors(errors) {
      if (errors.invalidUsers.length > 0) {
        errors.invalidUsers.forEach((user) => {
          let index = this.emergencyContacts.findIndex((u) => u.id == user.id);
          if (index > -1) {
            this.emergencyContacts.splice(index, 1);
            this.originalEmergencyContacts.splice(index, 1);
          }
          index = this.$store.getters.usersSelected.findIndex(
            (u) => u == user.id,
          );
          if (index > -1) {
            this.$store.commit(
              'updateUsersSelected',
              this.$store.getters.usersSelected.splice(index, 1),
            );
          }
        });
        if (this.emergencyContacts.length == 0) {
          this.$emit('reset');
        }
      }
      if (errors.invalidEmergencyContacts.length > 0) {
        const invalidIds = errors.invalidEmergencyContacts.map((ec) => ec.id);
        for (let index = 0; index < this.emergencyContacts.length; index++) {
          const user = this.emergencyContacts[index];
          const originalUser = this.originalEmergencyContacts[index];
          user.emergencyContacts = user.emergencyContacts.filter(
            (ec) => !invalidIds.includes(ec.id),
          );
          originalUser.emergencyContacts =
            originalUser.emergencyContacts.filter(
              (ec) => !invalidIds.includes(ec.id),
            );
        }
      }
      if (errors.hasIndividualErrors) {
        errors.users.forEach((user) => {
          const index = this.emergencyContacts.findIndex(
            (u) => u.id == user.id,
          );
          let hasError = false;
          user.emergencyContacts.forEach((ec, i) => {
            if (JSON.stringify(ec.errors) != '{}') {
              this.$set(user.emergencyContacts, i, ec);
              hasError = true;
            }
          });
          if (hasError) {
            this.$set(this.emergencyContacts, index, user);
          }
        });
      }
    },
    saveChanges() {
      const differences = this.getDifferences();

      if (differences.length == 0) {
        return new Promise((resolve) => resolve());
      }
      const url = 'mass-edit-users-emergency-contacts?format=json';
      const params = {
        loaderText: 'Saving...',
        users: differences,
      };
      if (this.$store.getters.isInternalAdmin) {
        params.companyId = this.$store.getters.selectedCompany.id;
      }
      return this.$axios.post(url, params);
    },
    getUsersEmergencyContacts() {
      const url = 'get-users-emergency-contacts?format=json';
      const params = {
        loaderText: 'Loading...',
        userIds: this.$store.getters.usersSelected,
      };
      if (this.$store.getters.isInternalAdmin) {
        params.companyId = this.$store.getters.selectedCompany.id;
      }

      this.$axios.post(url, params).then((response) => {
        this.originalEmergencyContacts = response.data;
        this.emergencyContacts = JSON.parse(
          JSON.stringify(this.originalEmergencyContacts),
        );
      });
    },
    deleteEmergencyContact(user, index) {
      user.emergencyContacts.splice(index, 1);
    },
    addEmergencyContact(user) {
      user.emergencyContacts.push({
        name: '',
        relationToUser: '',
        phone: '',
      });
    },
  },
  mounted() {
    this.getUsersEmergencyContacts();
  },
  filters: {
    truncate(text, length = 25) {
      if (text.length <= length) {
        return text;
      }
      return text.substring(0, length) + '...';
    },
  },
};
</script>
