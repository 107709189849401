<template>
  <v-container fluid>
    <v-row>
      <v-col align="center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="initGlobalSettings" color="msaBlue" dark>
              <v-icon>mdi-pencil</v-icon> Global Edit
            </v-btn>
          </template>
          Apply to all users
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-responsive :height="tableHeight" class="overflow-auto">
          <v-row
            v-for="(item, index) in detailsAndPermissions"
            :key="item.id"
            align="center"
            class="flex-nowrap"
          >
            <v-col :style="styles.general">
              <v-text-field
                v-model="item.firstName"
                :rules="rules.firstName"
                dense
                label="First name"
                maxlength="75"
                outlined
              >
                <template v-slot:label>
                  First name<span class="red--text">*</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col :style="styles.general">
              <v-text-field
                v-model="item.lastName"
                :rules="rules.lastName"
                dense
                label="Last name"
                maxlength="75"
                outlined
              >
                <template v-slot:label>
                  Last name<span class="red--text">*</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col :style="styles.general">
              <v-text-field
                v-model="item.email"
                :error-messages="item.errors ? item.errors.email : []"
                :rules="rules.email(item.isWebAdministrator)"
                @blur="clearNull(item)"
                @input="item.errors ? delete item.errors.email : null"
                clearable
                dense
                label="Email"
                maxlength="64"
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col :style="styles.general">
              <v-text-field
                v-model="item.title"
                :rules="rules.title"
                @blur="clearNull(item)"
                clearable
                dense
                label="Title"
                maxlength="64"
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col :style="styles.general">
              <v-text-field
                v-model="item.hireDate"
                :rules="rules.hireDate"
                @blur="clearNull(item)"
                clearable
                dense
                label="Hire Date"
                maxlength="10"
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col :style="styles.webAdmin">
              <v-checkbox
                v-model="item.isWebAdministrator"
                :disabled="item.id == $store.getters.user.id"
                :false-value="0"
                :true-value="1"
                @change="updateWebAdminPermission(item, $event)"
                class="ma-0"
                label="Web Admin"
              >
              </v-checkbox>
            </v-col>
            <v-col :style="styles.supervisor">
              <v-checkbox
                v-model="item.isSupervisor"
                :false-value="0"
                :true-value="1"
                @change="updateSupervisorPermission(item, $event)"
                class="ma-0"
                label="Supervisor"
              >
              </v-checkbox>
            </v-col>
            <v-col :style="styles.manager">
              <v-checkbox
                v-model="item.isManager"
                :false-value="0"
                :true-value="1"
                @change="updateManagerPermission(item, $event)"
                class="ma-0"
                label="Manager"
              >
              </v-checkbox>
            </v-col>
            <v-col :style="styles.safetyRep">
              <v-checkbox
                v-model="item.isSafetyRep"
                :false-value="0"
                :true-value="1"
                @change="updateSafetyRepPermission(item, $event)"
                class="ma-0"
                label="Safety"
              >
              </v-checkbox>
            </v-col>
            <v-col
              v-if="
                isInternalAdmin &&
                $store.getters.selectedCompany.companyGroupId > 0
              "
              :style="styles.groupAdmin"
            >
              <v-checkbox
                v-model="item.isGroupAdministrator"
                :false-value="0"
                :true-value="1"
                @change="updateGroupAdminPermission(item, $event)"
                class="ma-0"
                label="Group Admin"
              >
              </v-checkbox>
            </v-col>
            <v-col :style="{ maxWidth: '80px' }">
              <v-btn
                @click="unselect(index)"
                class="mb-4"
                color="red"
                icon
                small
                text
              >
                <v-icon>mdi-minus-circle-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-responsive>
      </v-col>
    </v-row>

    <!-- global settings -->
    <v-dialog v-model="globalSettingsDialog" width="500px" persistent>
      <v-card>
        <v-card-title class="msaBlue white--text"> Global Edit </v-card-title>
        <v-card-text class="pt-4">
          <v-row>
            <v-col>
              <v-text-field
                v-model="globalValues.edited.title"
                dense
                hide-details
                label="Title"
                maxlength="64"
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="globalValues.edited.hireDate"
                :rules="rules.hireDate"
                clearable
                dense
                label="Hire Date"
                maxlength="10"
                outlined
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox
                v-model="globalValues.edited.isWebAdministrator"
                :false-value="0"
                :indeterminate="indeterminate.isWebAdministrator"
                :true-value="1"
                @change="updateAllWebAdminPermissions($event)"
                class="ma-0"
                hide-details
                label="Web Admin"
              >
              </v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                v-model="globalValues.edited.isSupervisor"
                :false-value="0"
                :indeterminate="indeterminate.isSupervisor"
                :true-value="1"
                @change="updateAllSupervisorPermissions($event)"
                class="ma-0"
                hide-details
                label="Supervisor"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox
                v-model="globalValues.edited.isManager"
                :false-value="0"
                :indeterminate="indeterminate.isManager"
                :true-value="1"
                @change="updateAllManagerPermissions"
                class="ma-0"
                hide-details
                label="Manager"
              >
              </v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                v-model="globalValues.edited.isSafetyRep"
                :false-value="0"
                :indeterminate="indeterminate.isSafetyRep"
                :true-value="1"
                @change="updateAllSafetyRepPermissions"
                class="ma-0"
                hide-details
                label="Safety"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="isInternalAdmin">
              <v-checkbox
                v-model="globalValues.edited.isGroupAdministrator"
                :false-value="0"
                :indeterminate="indeterminate.isGroupAdministrator"
                :true-value="1"
                @change="updateAllGroupAdminPermissions"
                class="ma-0"
                hide-details
                label="Group Admin"
              >
              </v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="globalSettingsDialog = false" color="red" dark text>
            Cancel
          </v-btn>
          <v-btn @click="applyGlobalSettings" class="msaBlue white--text">
            apply to all
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'UserMassEditDetailsAndPermissions',
  data() {
    return {
      globalValues: {
        initial: {
          title: '',
          hireDate: '',
          isWebAdministrator: null,
          isSupervisor: null,
          isManager: null,
          isSafetyRep: null,
          isGroupAdministrator: null,
        },
        edited: {},
      },
      styles: {
        general: {
          minWidth: '270px',
          paddingTop: '16px',
        },
        webAdmin: {
          minWidth: '90px',
          maxWidth: '140px',
        },
        supervisor: {
          minWidth: '120px',
          maxWidth: '120px',
        },
        manager: {
          minWidth: '110px',
          maxWidth: '110px',
        },
        safetyRep: {
          minWidth: '90px',
          maxWidth: '140px',
        },
        groupAdmin: {
          minWidth: '110px',
          maxWidth: '150px',
        },
      },
      rules: {
        lastName: [(v) => this.$helpers.required(v, 'Last Name')],
        firstName: [(v) => this.validateFirstName(v)],
        email: (isWebAdmin) => [
          (v) => this.$helpers.validateEmail(v, isWebAdmin),
        ],
        title: [(v) => this.$helpers.validateTitle(v)],
        hireDate: [(v) => this.$helpers.validateDate(v)],
      },
      detailsAndPermissions: [],
      originalDetailsAndPermissions: [],
      itemHeight: 72,
      globalSettingsDialog: false,
      indeterminate: {
        isWebAdministrator: false,
        isSupervisor: false,
        isManager: false,
        isSafetyRep: false,
        isGroupAdministrator: false,
      },
    };
  },
  computed: {
    isInternalAdmin() {
      return this.$store.getters.isInternalAdmin;
    },
    screenHeight() {
      return window.innerHeight;
    },
    tableHeight() {
      const fiveItems = this.itemHeight * 5;
      const top = 180;
      const bottom = 70;
      if (this.screenHeight - top - bottom > fiveItems) {
        return this.screenHeight - top - bottom;
      } else {
        return fiveItems;
      }
    },
  },
  mounted() {
    this.getUsersDetailsAndPermissions();
  },
  methods: {
    getUsersDetailsAndPermissions() {
      this.detailsAndPermissions = [];
      this.originalDetailsAndPermissions = [];
      const url = 'get-users-details-and-permissions?format=json';
      const params = {
        loaderText: 'Loading...',
        userIds: this.$store.getters.usersSelected,
      };
      if (this.isInternalAdmin) {
        params.companyId = this.$store.getters.selectedCompany.id;
      }

      this.$axios.post(url, params).then((response) => {
        this.detailsAndPermissions = response.data;
        this.originalDetailsAndPermissions = JSON.parse(
          JSON.stringify(this.detailsAndPermissions),
        );
      });
    },
    initGlobalSettings() {
      /*
        Will check the first user obj in detailsAndPermissions against all other users in the list.
        If ANY user has a different value than the first user, the button will be indeterminate because
        there are a mix of values for a given permission. If not, than the first user represents the
        value of all users.

        Note: Loop checks the firstUser against the firstUser because it will be less performant to exclude it
        & it does not affect outcome.
      */

      // Init global values to firstUser
      const firstUser = this.detailsAndPermissions[0];
      this.globalValues.initial.isWebAdministrator =
        firstUser.isWebAdministrator;
      this.globalValues.initial.isSupervisor = firstUser.isSupervisor;
      this.globalValues.initial.isManager = firstUser.isManager;
      this.globalValues.initial.isSafetyRep = firstUser.isSafetyRep;
      this.globalValues.initial.isGroupAdministrator =
        firstUser.isGroupAdministrator;

      // Overwrite global values if different than firstUser
      const indeterminatePermissions = new Set();
      const numPermissions = this.isInternalAdmin ? 5 : 4; // No Group Admin

      if (this.detailsAndPermissions.length > 1) {
        for (const user of this.detailsAndPermissions) {
          if (user.isWebAdministrator !== firstUser.isWebAdministrator) {
            this.indeterminate.isWebAdministrator = true;
            this.globalValues.initial.isWebAdministrator = null;
            indeterminatePermissions.add('Web Admin');
          }
          if (user.isSupervisor !== firstUser.isSupervisor) {
            this.indeterminate.isSupervisor = true;
            this.globalValues.initial.isSupervisor = null;
            indeterminatePermissions.add('Supervisor');
          }
          if (user.isManager !== firstUser.isManager) {
            this.indeterminate.isManager = true;
            this.globalValues.initial.isManager = null;
            indeterminatePermissions.add('Manager');
          }
          if (user.isSafetyRep !== firstUser.isSafetyRep) {
            this.indeterminate.isSafetyRep = true;
            this.globalValues.initial.isSafetyRep = null;
            indeterminatePermissions.add('Safety');
          }
          if (
            this.isInternalAdmin &&
            user.isGroupAdministrator !== firstUser.isGroupAdministrator
          ) {
            this.indeterminate.isGroupAdministrator = true;
            this.globalValues.initial.isGroupAdministrator = null;
            indeterminatePermissions.add('Group Admin');
          }

          // No need to continue looping if all values modified
          if (indeterminatePermissions.size == numPermissions) {
            break;
          }
        }
      }

      // Initialize rest of data and open dialog when it's done processing
      this.globalValues.initial.title = '';
      this.globalValues.initial.hireDate = '';
      this.globalValues.edited = { ...this.globalValues.initial };
      this.globalSettingsDialog = true;
    },
    validateFirstName(v) {
      if (
        v.toLowerCase().trim() == this.$constants.ONE_LIFE_ACCOUNT.FIRST_NAME
      ) {
        return 'Invalid name';
      }

      return this.$helpers.required(v, 'First Name');
    },
    next() {
      this.saveChanges()
        .then(() => {
          this.$emit('next');
        })
        .catch((errors) => {
          this.handleErrors(errors.response.data);
        });
    },
    done() {
      this.saveChanges()
        .then(() => {
          this.$emit('done');
        })
        .catch((errors) => {
          this.handleErrors(errors.response.data);
        });
    },
    saveChanges() {
      const differences = this.getDifferences();

      if (differences.length == 0) {
        return new Promise((resolve) => resolve());
      }
      const url = 'mass-edit-users-details-and-permissions?format=json';
      const params = {
        loaderText: 'Saving...',
        users: differences,
      };
      if (this.isInternalAdmin) {
        params.companyId = this.$store.getters.selectedCompany.id;
      }
      return this.$axios.post(url, params);
    },
    applyGlobalSettings() {
      const changedKeys = [];
      for (let key in this.globalValues.edited) {
        if (
          (key == 'hireDate' || key == 'title') &&
          !this.globalValues.edited[key]
        ) {
          this.globalValues.edited[key] = '';
        }
        if (this.globalValues.edited[key] != this.globalValues.initial[key]) {
          changedKeys.push(key);
        }
      }
      if (changedKeys.length) {
        this.detailsAndPermissions.forEach((user) => {
          changedKeys.forEach((key) => {
            if (
              user.id == this.$store.getters.user.id &&
              key == 'isWebAdministrator'
            ) {
              return;
            }
            user[key] = this.globalValues.edited[key];
          });

          if (user.isWebAdministrator && !user.email.length) {
            if (!user.errors) {
              user.errors = {};
            }
            user.errors.email = 'Email is required';
          }

          if (!user.isWebAdministrator && user.errors) {
            delete user.errors.email;
          }
        });
      }
      this.globalValues.initial = { ...this.globalValues.edited };
      this.globalSettingsDialog = false;
    },
    getDifferences() {
      const differences = [];
      for (let index = 0; index < this.detailsAndPermissions.length; index++) {
        const original = this.originalDetailsAndPermissions[index];
        const edited = this.detailsAndPermissions[index];
        if (JSON.stringify(original) !== JSON.stringify(edited)) {
          differences.push(edited);
        }
      }
      return differences;
    },
    updateAllWebAdminPermissions(val) {
      if (!val) {
        this.globalValues.edited.isGroupAdministrator = val;
        this.updateAllGroupAdminPermissions(val);
      }
      this.indeterminate.isWebAdministrator = false;
    },
    updateAllSupervisorPermissions(val) {
      const updatePermissions = () => {
        if (!val) {
          this.globalValues.edited.isManager = 0;
          this.indeterminate.isManager = false;
          this.indeterminate.isSupervisor = false;
        }
      };

      if (!val) {
        const isAnySupervising = this.detailsAndPermissions.find(
          (user) => user.isSupervising,
        );
        if (isAnySupervising) {
          this.$root.showMessage(
            'Warning',
            'If any user is added as a supervisor to any employee(s), they will be automatically removed. Are you sure you want to proceed?',
            () => updatePermissions(),
            () => (this.globalValues.edited.isSupervisor = val ? 0 : 1),
            'Confirm',
            'Cancel',
          );
          return;
        }
        updatePermissions();
        return;
      }

      updatePermissions();
    },
    updateAllManagerPermissions(val) {
      if (val && !this.globalValues.edited.isSupervisor) {
        this.globalValues.edited.isSupervisor = 1;
        this.updateAllSupervisorPermissions(1);
      }
      this.indeterminate.isManager = false;
    },
    updateAllSafetyRepPermissions(val) {
      if (!val) {
        this.globalValues.edited.isGroupAdministrator = val;
      }
      this.indeterminate.isSafetyRep = false;
    },
    updateAllGroupAdminPermissions(val) {
      if (val && !this.globalValues.edited.isWebAdministrator) {
        this.globalValues.edited.isWebAdministrator = 1;
        this.updateAllWebAdminPermissions(1);
      }
      this.indeterminate.isGroupAdministrator = false;
    },
    updateWebAdminPermission(item, val) {
      if (item.id == this.$store.getters.user.id && !val) {
        return;
      }

      if (item.isGroupAdministrator && !val) {
        item.isGroupAdministrator = 0;
      }

      if (val && !item.email.length) {
        if (!item.errors) {
          item.errors = {};
        }
        item.errors.email = 'Email is required';
      }

      if (!val && item.errors && item.errors.email) {
        delete item.errors.email;
      }
    },
    updateSupervisorPermission(item, val) {
      const confirm = () => {
        if (item.isManager) {
          item.isManager = 0;
        }
      };
      if (!val && item.isSupervising) {
        this.$root.showMessage(
          'Warning',
          'If the user is added as a supervisor to any employee(s), they will be automatically removed. Are you sure you want to proceed?',
          () => confirm(),
          () => {
            item.isSupervisor = 1;
          },
          'Confirm',
          'Cancel',
        );
        return;
      }
      confirm();
    },
    updateManagerPermission(item, value) {
      if (value && !item.isSupervisor) {
        item.isSupervisor = 1;
      }
    },
    updateSafetyRepPermission(item, value) {
      if (value && !item.isSafetyRep) {
        item.isSafetyRep = 1;
      }
    },
    updateGroupAdminPermission(item, value) {
      if (value && !item.isWebAdministrator) {
        item.isWebAdministrator = 1;
      }
    },
    unselect(index) {
      this.detailsAndPermissions.splice(index, 1);
      this.originalDetailsAndPermissions.splice(index, 1);
      this.$store.commit(
        'updateUsersSelected',
        this.originalDetailsAndPermissions.map((user) => user.id),
      );
      if (this.originalDetailsAndPermissions.length == 0) {
        this.$emit('done');
      }
    },
    clearNull(user) {
      for (let key in user) {
        if (user[key] === null) {
          user[key] = '';
        }
      }
    },
    handleErrors(errors) {
      if (errors.generalErrors && errors.generalErrors.invalidUsers) {
        errors.generalErrors.invalidUsers.forEach((user) => {
          let index = this.detailsAndPermissions.findIndex(
            (u) => u.id == user.id,
          );
          if (index > -1) {
            this.detailsAndPermissions.splice(index, 1);
            this.originalDetailsAndPermissions.splice(index, 1);
          }
          index = this.$store.getters.usersSelected.findIndex(
            (u) => u == user.id,
          );
          if (index > -1) {
            this.$store.commit(
              'updateUsersSelected',
              this.$store.getters.usersSelected.splice(index, 1),
            );
          }
        });
        if (this.detailsAndPermissions.length == 0) {
          this.$emit('reset');
        }
      }
    },
  },
};
</script>
